<template>
  <div class="view pa24">
    <el-page-header @back="goBack" content="出库记录详情">
    </el-page-header>
    <div>
      <el-form :model="serchParams" class="demo-form-inline">
        <el-row style="padding-top: 20px;">
          <el-col :span="4">
            <el-form-item label="代理商" label-width="100px" label-position="left" class="fromItem">
              <el-select v-model="serchParams.dealerId" placeholder="请选择" clearable >
                <el-option
                    v-for="item in dealerList"
                    :key="item.id"
                    :label="item.dealerName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="margin-left: 15px" :span="4">
            <el-button type="primary" class="myBtn" @click="serchquery">搜索</el-button>
            <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>
        <el-table-column
            prop="className"
            align="center"
            label="产品类别"
        />
        <el-table-column
          prop="handle"
          align="center"
          label="标识"
		  width="360"
        >
          <template slot-scope="scope">
            {{scope.row.handle}}
            <span :class="scope.row.type == 3?'name_status':'name_status1'">{{scope.row.type == 3 ? '箱' : '盒'}}</span>
            <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="warehouseName"
          align="center"
          label="仓库"
        />
		<el-table-column
		    align="center"
        :label="labelName"
		>
		<template slot-scope="scope">
			<div>{{scope.row.dealerCode?scope.row.dealerCode:'--'}}</div>
		</template>
		</el-table-column>
        <el-table-column
            prop="nickName"
            align="center"
            label="出库人"
        />
        <el-table-column
          prop="createTime"
          align="center"
          label="出库时间"
        />
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.type == 3" type="text" @click="boxDetail(scope.row)">查看</el-button>
            <el-button  type="text" @click="detail(scope.row)">溯源</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

<detail ref="boxDetail"></detail>
    <page-timeline ref="timeline"/>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { warehouseLogPage,getDealer } from '@/api/warehouseManage.js'
import detail from "./detail.vue";
import PageTimeline from "@/pages/dashboard/components/detailDialog/PageTimeline.vue";
export default {
  name: "dealerList",
  components: {
    commonTable,detail,PageTimeline
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
      wareStatus:2,
      dealerList:[],
      serchParams:{
          dealerId:null
      },
      labelName:''
    };
  },
  created() {
    this.getList()
    this.getDealerList()
    let userInfo = JSON.parse(localStorage.getItem('info'));
    let roleType = userInfo.userType
    if(roleType < 3) { // 代理商,获取经销商列表
      this.labelName='代理商'
    }else if(roleType == 3) { // 经销商
      this.labelName='经销商'
    }
  },
  methods: {
    detail(row){
      this.$refs.timeline.show(row.pageHandleId)
    },
    goBack() {
      this.$router.push({path:'/crk/warehouseLogOut'})
    },
    getDealerList(){
      getDealer().then(res=>{
        if (res.data){
          this.dealerList = res.data
        }
      })
    },
	  copy(context) {
	              // 创建输入框元素
	              let oInput = document.createElement('input');
	              // 将想要复制的值
	              oInput.value = context;
	              // 页面底部追加输入框
	              document.body.appendChild(oInput);
	              // 选中输入框
	              oInput.select();
	              // 执行浏览器复制命令
	              document.execCommand('Copy');
	              // 弹出复制成功信息
	              this.$message.success('复制成功');
	              // 复制后移除输入框
	              oInput.remove();
	          },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddDealer(type, id) {
          this.$refs.addDealerDialog.openDialog(type, id)
      },
    serchquery(){
      this.getList()
    },
    reset(){
      this.serchParams.dealerId=null
      this.getList()
    },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          wareStatus:this.wareStatus,
          batchId: this.$route.query.id
        }
        Object.assign(params,this.serchParams)
        warehouseLogPage(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      },
    boxDetail(data){
      this.$refs.boxDetail.init(data,this.wareStatus)
      this.$refs.boxDetail.dialogTableVisible= true
    }
  }
}
</script>

<style lang="scss" scoped>
	.name_status {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(63, 114, 246, 0.11);
		border: 1px solid #3F72F6;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #3F72F6;
		text-align: center;
	}

	.name_status1 {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(243, 136, 46, 0.11);
		border: 1px solid #F3882E;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #F3882E;
		text-align: center;
	}

	.copyTxt {
		display: inline-block;
		margin-left: 5px;
		cursor: pointer;
		color: #3F72F6;
	}
</style>

